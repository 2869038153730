import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ApiClients } from "api/clients";
import { LegacyDealApi } from "api/legacyApi";
import type { RootState } from "./configureStore";
import { createAppSelector } from "./hooks";

interface IDealDataSliceState {
  exercisedLegs: {
    loading: boolean;
    error?: string;
    dealId: number;
    data: LegacyDealApi.ExercisedOption[];
  };
}

const initialState: IDealDataSliceState = {
  exercisedLegs: {
    loading: false,
    dealId: 0,
    data: []
  }
};

export const getDealExercisedOptions = createAsyncThunk<LegacyDealApi.ExercisedOption[], number, { state: RootState }>(
  "dealData/exercisedLegs",
  async (dealId, api) => {
    const data = await ApiClients.legacy.dealsClient.getExercisedLegs(dealId);
    return (data as any)?.Body as LegacyDealApi.ExercisedOption[];
  },
  {
    condition: (dealId, api) => {
      const state = api.getState().DealData.exercisedLegs;
      return dealId > 0 && (dealId !== state.dealId || (!state.loading && state.error == null));
    }
  }
);

export const selectDealExercisedOptions = createAppSelector(
  [state => state.DealData.exercisedLegs, (state, dealId) => dealId],
  (legs, dealId) => {
    return legs.dealId === dealId ? legs.data : [];
  }
);

const dealDataSlice = createSlice({
  initialState: initialState,
  name: "dealData",
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getDealExercisedOptions.pending, (state, action) => {
        state.exercisedLegs.loading = true;
        state.exercisedLegs.error = undefined;
        state.exercisedLegs.dealId = action.meta.arg;
      })
      .addCase(getDealExercisedOptions.fulfilled, (state, action) => {
        if (state.exercisedLegs.dealId !== action.meta.arg) return;

        state.exercisedLegs.loading = false;
        state.exercisedLegs.data = action.payload;
      })
      .addCase(getDealExercisedOptions.rejected, state => {
        state.exercisedLegs.loading = false;
        state.exercisedLegs.error = "Failed to load exercised options for deal.";
      });
  }
});

export default dealDataSlice.reducer;
