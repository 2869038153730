import { LoginService } from "internal/dcc-services/LoginService";
import { put, takeEvery, takeLatest, call, take, actionChannel } from "redux-saga/effects";
import {
  ConfigBaseAction,
  DefaultFeesFailed,
  DefaultFeesSuccess,
  DefaultFeesUpdateFailed,
  DefaultFeesUpdateSuccess,
  DEFAULT_FEES_REQUESTED,
  DEFAULT_FEES_UPDATE_REQUESTED,
  GetAllProfilesFailed,
  GetAllProfilesSuccess,
  InternalDetailsFailed,
  InternalDetailsSuccess,
  InternalDetailsUpdateFailed,
  InternalDetailsUpdateSuccess,
  INTERNAL_DETAILS_REQUESTED,
  INTERNAL_DETAILS_UPDATE_REQUESTED,
  MarketSettingsFailed,
  MarketSettingsSuccess,
  MarketSettingsUpdateFailed,
  MarketSettingsUpdateSuccess,
  MARKET_SETTINGS_REQUESTED,
  MARKET_SETTINGS_UPDATE_REQUESTED,
  PROFILE_GET_ALL_REQUEST,
  PROFILE_UPDATE_DETAILS_REQUEST,
  PROFILE_UPDATE_PERIODS_REQUEST,
  PROFILE_UPDATE_REQUEST,
  UnderlyingQuarterlyFailed,
  UnderlyingQuarterlySucceeded,
  UNDERLYING_QUARTERLY_REQUESTED,
  UpdatePeriodsFailed,
  UpdatePeriodsSuccess,
  UpdateProfileDetailsFailed,
  UpdateProfileDetailsSuccess,
  UpdateProfileFailed,
  UpdateProfileSuccess
} from "sagas/reducers/config";
import {
  ConfigApiFactory,
  ConfigModel,
  FeesModel,
  TradableModel,
  MarketSettingsModel,
  KeyedValue,
  ExchangeFee,
  Period,
  OTCFee,
  ProfileModel,
  UpdateProfile,
  CustomProfileModel,
  PeriodModel,
  ClearerAccountModel
} from "../api";
import {
  ClearerAccount,
  creationYearValue,
  DefaultFees,
  HTTPReply,
  InternalDetailsConfig,
  KeyValue,
  MarketSettings,
  Requestor,
  Tradable
} from "../types/config";
import axios from "axios";
import { getNetDate } from "internal/dcc-types/dates";
import { TakeableChannel } from "redux-saga";
const configClient = ConfigApiFactory();

function* getAllProfilesWorker(action: ConfigBaseAction<string>) {
  try {
    axios.defaults.headers.common["Authorization"] = `Bearer ${LoginService.token()}`;

    let res: HTTPReply = yield call(configClient.apiConfigGetallprofilesPost);
    const reply = res.data;
    const data: ProfileModel = reply.Body;

    yield put(GetAllProfilesSuccess(data));
  } catch (error) {
    const e = error as any;
    yield put(GetAllProfilesFailed({ Message: e.message, Status: e.response?.status, StatusText: e.response?.statusText }));
  }
}

function* updateProfilesWorker(action: ConfigBaseAction<UpdateProfile>) {
  try {
    axios.defaults.headers.common["Authorization"] = `Bearer ${LoginService.token()}`;

    let res: HTTPReply = yield call(configClient.apiConfigUpdateprofilePost, action.payload);
    const reply = res.data;
    const data: string = reply.Body;

    yield put(UpdateProfileSuccess(data));
  } catch (error) {
    const e = error as any;
    yield put(UpdateProfileFailed({ Message: e.message, Status: e.response?.status, StatusText: e.response?.statusText }));
  }
}

function* updateProfileDetailsWorker(action: ConfigBaseAction<CustomProfileModel>) {
  try {
    axios.defaults.headers.common["Authorization"] = `Bearer ${LoginService.token()}`;

    let res: HTTPReply = yield call(configClient.apiConfigUpdateprofiledetailsPost, action.payload);
    const reply = res.data;
    if (reply?.Error && reply?.Error?.length !== 0) {
      yield put(
        UpdateProfileDetailsFailed({
          Message: reply?.Error[0]?.Message,
          Status: reply?.Error[0]?.Code,
          StatusText: reply?.Status
        })
      );
    }

    const data: string = reply.Body;

    yield put(UpdateProfileDetailsSuccess(data));
  } catch (error) {
    const e = error as any;
    yield put(UpdateProfileDetailsFailed({ Message: e.message, Status: e.response?.status, StatusText: e.response?.statusText }));
  }
}

function* updateProfilePeriodsWorker(action: ConfigBaseAction<Array<PeriodModel>>) {
  try {
    axios.defaults.headers.common["Authorization"] = `Bearer ${LoginService.token()}`;

    let res: HTTPReply = yield call(configClient.apiConfigUpdateperiodsPost, action.payload);
    const reply = res.data;
    if (reply?.Error && reply?.Error?.length !== 0) {
      yield put(
        UpdatePeriodsFailed({
          Message: reply?.Error[0]?.Message,
          Status: reply?.Error[0]?.Code,
          StatusText: reply?.Status
        })
      );
    }
    const data: string = reply.Body;

    yield put(UpdatePeriodsSuccess(data));
  } catch (error) {
    const e = error as any;
    yield put(UpdatePeriodsFailed({ Message: e.message, Status: e.response?.status, StatusText: e.response?.statusText }));
  }
}

function* getUnderlyingQuarterlyWorker(action: ConfigBaseAction<Tradable>) {
  try {
    axios.defaults.headers.common["Authorization"] = `Bearer ${LoginService.token()}`;

    let res: HTTPReply = yield call(configClient.apiConfigGetunderlyingquartersPost, {
      ProductCode: action.payload?.Ticker || "",
      Price: action.payload?.Price || 0,
      Volume: action.payload?.Volume || 0,
      EffectiveDate: getNetDate(action?.payload?.EffectiveDate),
      Exchange: action.payload?.Exchange,
      ThirtyMinutes: action.payload?.ThirtyMinutes
    });
    const reply = res.data;
    const data: TradableModel[] = reply.Body;

    yield put(UnderlyingQuarterlySucceeded(data));
  } catch (error) {
    const e = error as any;
    yield put(UnderlyingQuarterlyFailed({ Message: e.message, Status: e.response?.status, StatusText: e.response?.statusText }));
  }
}

function* setInternalDetailsWorker(action: ConfigBaseAction<InternalDetailsConfig>) {
  try {
    axios.defaults.headers.common["Authorization"] = `Bearer ${LoginService.token()}`;

    // mapping
    let data: InternalDetailsConfig = action.payload!;

    let param: ConfigModel;
    param = {
      Books: data.Books
        ? data.Books?.map(x => {
            return { Id: x.id, Value: x.value, SortOrder: x.sortOrder, Enabled: x.Enabled };
          })
        : [],
      Traders: data.Traders
        ? data.Traders?.map(x => {
            return { Id: x.id, Value: x.value, SortOrder: x.sortOrder };
          })
        : [],
      Counterparties: data.Counterparties,
      FirmnessDependencies: data.FirmnessDependencies
        ? data.FirmnessDependencies?.map(x => {
            return { Id: x.id, Value: x.value, SortOrder: x.sortOrder };
          })
        : [],
      EnvironCreationYears: data.EnvironCreationYears
        ? data.EnvironCreationYears?.map(x => {
            return { Id: x.id, Value: x.value, SortOrder: x.sortOrder, Enabled: x.Enabled };
          })
        : [],
      EnvironExclusions: data.EnvironExclusions
        ? data.EnvironExclusions?.map(x => {
            return { Id: x.id, Value: x.value, SortOrder: x.sortOrder, Enabled: x.Enabled };
          })
        : [],
      EnvironProducts: data.EnvironProducts
        ? data.EnvironProducts?.map(x => {
            return {
              Id: x.id,
              Value: x.value,
              SortOrder: x.sortOrder,
              CreationYear: x.creationYear === creationYearValue.Applicable,
              GenerationYear: x.generationYear === creationYearValue.Applicable,
              Enabled: x.Enabled,
              CreationYearMessage: x.creationMessage,
              CurrencyCode: x.CurrencyCode || undefined
            };
          })
        : []
    };

    let res: HTTPReply = yield call(configClient.apiConfigUpdateconfigdataPost, param);
    const reply = res.data;
    if (reply.Error && reply.Error.length > 0) {
      const last = reply.Error[reply.Error.length - 1];
      yield put(InternalDetailsUpdateFailed({ Message: last.Message, Status: last.Code, StatusText: reply.Status }));
    } else {
      const rep: string = reply.Body;
      yield put(InternalDetailsUpdateSuccess(rep));
    }
  } catch (error) {
    const e = error as any;
    yield put(
      InternalDetailsUpdateFailed({
        Message: e.message,
        Status: e.response?.status,
        StatusText: e.response?.statusText
      })
    );
  }
}

function* getInternalDetailsWorker(action: ConfigBaseAction<Requestor>) {
  try {
    axios.defaults.headers.common["Authorization"] = `Bearer ${LoginService.token()}`;

    let res: HTTPReply = yield call(configClient.apiConfigGetconfigdataPost);
    const reply = res.data;

    const from: ConfigModel = reply.Body;
    let to: InternalDetailsConfig;
    // mapping
    to = {
      ...from,
      LoggedOnUserEmail: from?.LoggedOnUserEmail || "",
      LoggedOnUser: { id: from.LoggedOnUser?.Id || 0, value: from.LoggedOnUser?.Value || "" },
      Books: from.Books
        ? from.Books?.map(x => {
            return { id: x.Id || 0, value: x.Value || "", sortOrder: x.SortOrder, Enabled: x.Enabled || false };
          }).sort((a, b) => ((a.sortOrder || 0) < (b.sortOrder || 0) ? -1 : (a.sortOrder || 0) > (b.sortOrder || 0) ? 1 : 0))
        : [],
      Traders: from.Traders
        ? from.Traders?.map(x => {
            return { id: x.Id || 0, value: x.Value || "", sortOrder: x.SortOrder };
          }).sort((a, b) => ((a.sortOrder || 0) < (b.sortOrder || 0) ? -1 : (a.sortOrder || 0) > (b.sortOrder || 0) ? 1 : 0))
        : [],
      Counterparties: from.Counterparties
        ? from.Counterparties.sort((a, b) =>
            (a.SortOrder || 0) < (b.SortOrder || 0) ? -1 : (a.SortOrder || 0) > (b.SortOrder || 0) ? 1 : 0
          )
        : [],
      FirmnessDependencies: from.FirmnessDependencies
        ? from.FirmnessDependencies?.map(x => {
            return { id: x.Id || 0, value: x.Value || "", sortOrder: x.SortOrder };
          }).sort((a, b) => ((a.sortOrder || 0) < (b.sortOrder || 0) ? -1 : (a.sortOrder || 0) > (b.sortOrder || 0) ? 1 : 0))
        : [],
      EnvironExclusions: from.EnvironExclusions
        ? from.EnvironExclusions?.map(x => {
            return { id: x.Id || 0, value: x.Value || "", sortOrder: x.SortOrder, Enabled: x.Enabled };
          }).sort((a, b) => ((a.sortOrder || 0) < (b.sortOrder || 0) ? -1 : (a.sortOrder || 0) > (b.sortOrder || 0) ? 1 : 0))
        : [],
      EnvironCreationYears: from.EnvironCreationYears
        ? from.EnvironCreationYears?.map(x => {
            return { id: x.Id || 0, value: x.Value || "", sortOrder: x.SortOrder, Enabled: x.Enabled };
          }).sort((a, b) => ((a.sortOrder || 0) < (b.sortOrder || 0) ? -1 : (a.sortOrder || 0) > (b.sortOrder || 0) ? 1 : 0))
        : [],
      EnvironProducts: from.EnvironProducts
        ? from.EnvironProducts?.map(x => {
            return {
              id: x.Id || 0,
              value: x.Value || "",
              sortOrder: x.SortOrder,
              creationYear: x.CreationYear ? creationYearValue.Applicable : creationYearValue.NotApplicable,
              generationYear: x.GenerationYear ? creationYearValue.Applicable : creationYearValue.NotApplicable,
              Enabled: x.Enabled,
              CurrencyCode: x.CurrencyCode,
              creationMessage: x.CreationYearMessage
            };
          }).sort((a, b) => ((a.sortOrder || 0) < (b.sortOrder || 0) ? -1 : (a.sortOrder || 0) > (b.sortOrder || 0) ? 1 : 0))
        : []
    };

    yield put(InternalDetailsSuccess(to));
  } catch (error) {
    const e = error as any;
    yield put(InternalDetailsFailed({ Message: e.message, Status: e.response?.status, StatusText: e.response?.statusText }));
  }
}

function* setMarketSettingsUpdateWorker(action: ConfigBaseAction<MarketSettings>) {
  try {
    axios.defaults.headers.common["Authorization"] = `Bearer ${LoginService.token()}`;

    // mapping
    let data: MarketSettings = action.payload!;

    let param: MarketSettingsModel;
    param = {
      ...data,
      OrganizationId: data.OrgId,
      OrganizationName: data.OrgName,
      Interconnectors: data.Interconnectors
        ? data.Interconnectors?.map(x => {
            return { Id: x.id, Value: x.value, From: x.from, To: x.to, SortOrder: x.sortOrder };
          })
        : [],
      FloorStrikes: null,
      CapStrikes: null,
      UnitOfMeasures: null
    };

    let res: HTTPReply = yield call(configClient.apiConfigUpdatemarketsettingsPost, param);
    const reply = res.data;

    const rep: string = reply.Body;

    yield put(MarketSettingsUpdateSuccess(rep));
  } catch (error) {
    const e = error as any;
    yield put(MarketSettingsUpdateFailed({ Message: e.message, Status: e.response?.status, StatusText: e.response?.statusText }));
  }
}

function* getMarketSettingsWorker(action: ConfigBaseAction<Requestor>) {
  try {
    axios.defaults.headers.common["Authorization"] = `Bearer ${LoginService.token()}`;

    let res: HTTPReply = yield call(configClient.apiConfigGetmarketsettingsPost);
    const reply = res.data;

    const from: MarketSettingsModel = reply.Body;
    let to: MarketSettings;
    // mapping
    to = {
      OrgId: from.OrganizationId,
      OrgName: from.OrganizationName || "",
      Interconnectors: from.Interconnectors
        ? from.Interconnectors?.map(x => {
            return { id: x.Id || 0, value: x.Value || "", from: x.From || "", to: x.To || "", sortOrder: x.SortOrder };
          }).sort(x => x.sortOrder || 0)
        : [],
      FloorStrikes: from.FloorStrikes
        ? from.FloorStrikes?.map(x => {
            return { id: x.Id || 0, value: x.Value || "" };
          })
        : [],
      CapStrikes: from.CapStrikes
        ? from.CapStrikes?.map(x => {
            return { id: x.Id || 0, value: x.Value || "" };
          })
        : [],
      UnitOfMeasures: from.UnitOfMeasures
        ? from.UnitOfMeasures?.map(x => {
            return { id: x.Id || 0, value: x.Value || "" };
          })
        : []
    };

    yield put(MarketSettingsSuccess(to));
  } catch (error) {
    const e = error as any;
    yield put(MarketSettingsFailed({ Message: e.message, Status: e.response?.status, StatusText: e.response?.statusText }));
  }
}

function* setDefaultFeesWorker(action: ConfigBaseAction<DefaultFees>) {
  try {
    axios.defaults.headers.common["Authorization"] = `Bearer ${LoginService.token()}`;

    // mapping
    let data: DefaultFees = action.payload!;

    let param: FeesModel;
    param = {
      Brokers: data.Brokers.map(x => {
        const name: KeyedValue = { Id: x.Name.id, Value: x.Name.value };
        return {
          Name: name,
          Enabled: x.Enabled,
          EnabledOnASX: x.EnabledOnASX,
          EnabledOnFEX: x.EnabledOnFEX,
          EnabledOnOTC: x.EnabledOnOTC,
          EnabledOnGSH: x.EnabledOnGSH,
          EnabledOnENV: x.EnabledOnENV,
          EPFees: x.EPFees.map(i => {
            return {
              Id: i.id,
              EffectiveDate: getNetDate(i.EffectiveDate),
              Price: Number(i.Price),
              ProductName: i.value,
              UnitOfMeasure: { Id: i.UnitOfMeasure?.id, Value: i.UnitOfMeasure?.value },
              SortOrder: i.sortOrder
            };
          }),
          SRAFees: x.SRAFees.map(i => {
            return {
              Id: i.id,
              EffectiveDate: getNetDate(i.EffectiveDate),
              Price: Number(i.Price),
              ProductName: i.value,
              UnitOfMeasure: { Id: i.UnitOfMeasure?.id, Value: i.UnitOfMeasure?.value },
              SortOrder: i.sortOrder
            };
          }),
          ASXFees: x.ASXFees.map(i => {
            const fee: ExchangeFee = {
              Id: i.id,
              EffectiveDate: getNetDate(i.EffectiveDate),
              Price: Number(i.Price),
              ProductName: i.value,
              UnitOfMeasure: { Id: i.UnitOfMeasure?.id, Value: i.UnitOfMeasure?.value },
              SortOrder: i.sortOrder,
              Period: i.Period === Period.Peak ? Period.Peak : Period.Base
            };
            return fee;
          }),
          FEXFees: x.FEXFees.map(i => {
            const fee: ExchangeFee = {
              Id: i.id,
              EffectiveDate: getNetDate(i.EffectiveDate),
              Price: Number(i.Price),
              ProductName: i.value,
              UnitOfMeasure: { Id: i.UnitOfMeasure?.id, Value: i.UnitOfMeasure?.value },
              SortOrder: i.sortOrder,
              Period: i.Period === Period.Peak ? Period.Peak : Period.Base
            };
            return fee;
          }),
          AEMOFees: x.AEMOFees.map(i => {
            const fee: ExchangeFee = {
              Id: i.id,
              EffectiveDate: getNetDate(i.EffectiveDate),
              Price: Number(i.Price),
              ProductName: i.value,
              UnitOfMeasure: { Id: i.UnitOfMeasure?.id, Value: i.UnitOfMeasure?.value },
              SortOrder: i.sortOrder,
              Period: i.Period === Period.Peak ? Period.Peak : Period.Base
            };
            return fee;
          }),
          OTCFees: x.OTCFees.map(i => {
            let vol = Number(i.Volume);
            let res = undefined;
            if (vol && vol > 0) {
              res = vol;
            }

            const fee: OTCFee = {
              Condition: i.Condition || "",
              EffectiveDate: getNetDate(i.EffectiveDate),
              Id: i.id,
              Price: Number(i.Price),
              ProductName: i.value,
              UnitOfMeasure: { Id: i.UnitOfMeasure?.id, Value: i.UnitOfMeasure?.value },
              Volume: res,
              SortOrder: i.sortOrder
            };
            return fee;
          })
        };
      }),
      Clearers: data.Clearers.map(x => {
        const name: KeyedValue = { Id: x.Name.id, Value: x.Name.value };
        const accounts: ClearerAccountModel[] = x.Accounts
          ? x.Accounts?.map(x => {
              return { Id: x.id, Name: x.value, SortOrder: x.sortOrder, CloseoutMethodology: x.CloseoutMethodology };
            })
          : [];
        return {
          Name: name,
          Enabled: x.Enabled,
          EnabledOnASX: x.EnabledOnASX,
          EnabledOnFEX: x.EnabledOnFEX,
          ASXFees: x.ASXFees.map(i => {
            const fee: ExchangeFee = {
              Id: i.id,
              EffectiveDate: getNetDate(i.EffectiveDate),
              SortOrder: i.sortOrder,
              Price: Number(i.Price),
              ProductName: i.value,
              UnitOfMeasure: { Id: i.UnitOfMeasure?.id, Value: i.UnitOfMeasure?.value },
              Period: i.Period === Period.Peak ? Period.Peak : Period.Base
            };
            return fee;
          }),
          FEXFees: x.FEXFees.map(i => {
            const fee: ExchangeFee = {
              Id: i.id,
              EffectiveDate: getNetDate(i.EffectiveDate),
              SortOrder: i.sortOrder,
              Price: Number(i.Price),
              ProductName: i.value,
              UnitOfMeasure: { Id: i.UnitOfMeasure?.id, Value: i.UnitOfMeasure?.value },
              Period: i.Period === Period.Peak ? Period.Peak : Period.Base
            };
            return fee;
          }),
          Accounts: accounts
        };
      }),
      ASX: {
        Name: { Id: data.ASX?.Name.id || 1, Value: data.ASX?.Name.value || "ASX" },
        Fees: data.ASX?.Fees.map(i => {
          const fee: ExchangeFee = {
            Id: i.id,
            EffectiveDate: getNetDate(i.EffectiveDate),
            SortOrder: i.sortOrder,
            Price: Number(i.Price),
            ProductName: i.value,
            UnitOfMeasure: { Id: i.UnitOfMeasure?.id, Value: i.UnitOfMeasure?.value },
            Period: i.Period === Period.Peak ? Period.Peak : Period.Base
          };
          return fee;
        })
      },
      FEX: {
        Name: { Id: data.FEX?.Name.id || 2, Value: data.FEX?.Name.value || "FEX" },
        Fees: data.FEX?.Fees.map(i => {
          const fee: ExchangeFee = {
            Id: i.id,
            EffectiveDate: getNetDate(i.EffectiveDate),
            SortOrder: i.sortOrder,
            Price: Number(i.Price),
            ProductName: i.value,
            UnitOfMeasure: { Id: i.UnitOfMeasure?.id, Value: i.UnitOfMeasure?.value },
            Period: i.Period === Period.Peak ? Period.Peak : Period.Base
          };
          return fee;
        })
      },
      AEMO: {
        Name: { Id: data.AEMO?.Name.id || 2, Value: data.AEMO?.Name.value || "AEMO" },
        Fees: data.AEMO?.Fees.map(i => {
          const fee: ExchangeFee = {
            Id: i.id,
            EffectiveDate: getNetDate(i.EffectiveDate),
            SortOrder: i.sortOrder,
            Price: Number(i.Price),
            ProductName: i.value,
            UnitOfMeasure: { Id: i.UnitOfMeasure?.id, Value: i.UnitOfMeasure?.value },
            Period: i.Period === Period.Peak ? Period.Peak : Period.Base
          };
          return fee;
        })
      }
    };

    let res: HTTPReply = yield call(configClient.apiConfigUpdatedefaultfeesPost, param);
    const reply = res.data;
    if (reply?.Error && reply?.Error?.length !== 0) {
      yield put(
        DefaultFeesUpdateFailed({
          Message: reply?.Error[1]?.Message,
          Status: reply?.Error[0]?.Code,
          StatusText: reply?.Status
        })
      );
    }

    const rep: string = reply.Body;

    yield put(DefaultFeesUpdateSuccess(rep));
  } catch (error) {
    const e = error as any;
    yield put(DefaultFeesUpdateFailed({ Message: e.message, Status: e.response?.status, StatusText: e.response?.statusText }));
  }
}

function* getDefaultFeesWorker(action: ConfigBaseAction<Requestor>) {
  try {
    axios.defaults.headers.common["Authorization"] = `Bearer ${LoginService.token()}`;

    let res: HTTPReply = yield call(configClient.apiConfigGetdefaultfeesPost);
    const reply = res.data;

    const from: FeesModel = reply.Body;

    let to: DefaultFees;
    // mapping
    to = {
      Brokers: from.Brokers.map(x => {
        const name: KeyValue = { id: x.Name.Id || 0, value: x.Name.Value || "" };
        return {
          Name: name,
          Enabled: x.Enabled,
          EnabledOnASX: x.EnabledOnASX || false,
          EnabledOnFEX: x.EnabledOnFEX || false,
          EnabledOnOTC: x.EnabledOnOTC || false,
          EnabledOnGSH: x.EnabledOnGSH || false,
          EnabledOnENV: x.EnabledOnENV || false,
          EPFees: x.EPFees
            ? x.EPFees.map(i => {
                return {
                  id: i.Id,
                  EffectiveDate: getNetDate(i.EffectiveDate),
                  Price: i.Price,
                  value: i.ProductName,
                  UnitOfMeasure: { id: i.UnitOfMeasure?.Id || 0, value: i.UnitOfMeasure?.Value || "" },
                  sortOrder: i.SortOrder
                };
              }).sort((a, b) => ((a.sortOrder || 0) < (b.sortOrder || 0) ? -1 : (a.sortOrder || 0) > (b.sortOrder || 0) ? 1 : 0))
            : [],
          SRAFees: x.SRAFees
            ? x.SRAFees.map(i => {
                return {
                  id: i.Id,
                  EffectiveDate: getNetDate(i.EffectiveDate),
                  Price: i.Price,
                  value: i.ProductName,
                  UnitOfMeasure: { id: i.UnitOfMeasure?.Id || 0, value: i.UnitOfMeasure?.Value || "" },
                  sortOrder: i.SortOrder
                };
              }).sort((a, b) => ((a.sortOrder || 0) < (b.sortOrder || 0) ? -1 : (a.sortOrder || 0) > (b.sortOrder || 0) ? 1 : 0))
            : [],
          ASXFees:
            x.ASXFees && x.ASXFees?.length > 0
              ? x.ASXFees.map(i => {
                  return {
                    id: i.Id,
                    EffectiveDate: getNetDate(i.EffectiveDate),
                    Price: i.Price,
                    value: i.ProductName,
                    UnitOfMeasure: { id: i.UnitOfMeasure?.Id || 0, value: i.UnitOfMeasure?.Value || "" },
                    Period: i.Period,
                    sortOrder: i.SortOrder
                  };
                }).sort((a, b) => ((a.sortOrder || 0) < (b.sortOrder || 0) ? -1 : (a.sortOrder || 0) > (b.sortOrder || 0) ? 1 : 0))
              : [],
          FEXFees:
            x.FEXFees && x.FEXFees?.length > 0
              ? x.FEXFees.map(i => {
                  return {
                    id: i.Id,
                    EffectiveDate: getNetDate(i.EffectiveDate),
                    Price: i.Price,
                    value: i.ProductName,
                    UnitOfMeasure: { id: i.UnitOfMeasure?.Id || 0, value: i.UnitOfMeasure?.Value || "" },
                    Period: i.Period,
                    sortOrder: i.SortOrder
                  };
                }).sort((a, b) => ((a.sortOrder || 0) < (b.sortOrder || 0) ? -1 : (a.sortOrder || 0) > (b.sortOrder || 0) ? 1 : 0))
              : [],
          AEMOFees:
            x.AEMOFees && x.AEMOFees?.length > 0
              ? x.AEMOFees.map(i => {
                  return {
                    id: i.Id,
                    EffectiveDate: getNetDate(i.EffectiveDate),
                    Price: i.Price,
                    value: i.ProductName,
                    UnitOfMeasure: { id: i.UnitOfMeasure?.Id || 0, value: i.UnitOfMeasure?.Value || "" },
                    Period: i.Period,
                    sortOrder: i.SortOrder
                  };
                }).sort((a, b) => ((a.sortOrder || 0) < (b.sortOrder || 0) ? -1 : (a.sortOrder || 0) > (b.sortOrder || 0) ? 1 : 0))
              : [],
          OTCFees: x.OTCFees
            ? x.OTCFees.map(i => {
                return {
                  id: i.Id,
                  EffectiveDate: getNetDate(i.EffectiveDate),
                  Price: i.Price,
                  value: i.ProductName,
                  UnitOfMeasure: { id: i.UnitOfMeasure?.Id || 0, value: i.UnitOfMeasure?.Value || "" },
                  Condition: i.Condition,
                  Volume: i.Volume,
                  sortOrder: i.SortOrder
                };
              }).sort((a, b) => ((a.sortOrder || 0) < (b.sortOrder || 0) ? -1 : (a.sortOrder || 0) > (b.sortOrder || 0) ? 1 : 0))
            : []
        };
      }),
      Clearers: from.Clearers.map(x => {
        const name: KeyValue = { id: x.Name.Id || 0, value: x.Name.Value || "" };
        const accounts: ClearerAccount[] = x.Accounts
          ? x.Accounts?.map(x => {
              return {
                id: x.Id || 0,
                value: x.Name || "",
                sortOrder: x.SortOrder,
                CloseoutMethodology: x.CloseoutMethodology
              };
            })
          : [];
        return {
          Name: name,
          Enabled: x.Enabled,
          EnabledOnASX: x.EnabledOnASX || false,
          EnabledOnFEX: x.EnabledOnFEX || false,
          ASXFees:
            x.ASXFees && x.ASXFees?.length > 0
              ? x.ASXFees.map(i => {
                  return {
                    id: i.Id,
                    EffectiveDate: getNetDate(i.EffectiveDate),
                    Price: i.Price,
                    value: i.ProductName,
                    UnitOfMeasure: { id: i.UnitOfMeasure?.Id || 0, value: i.UnitOfMeasure?.Value || "" },
                    Period: i.Period,
                    sortOrder: i.SortOrder
                  };
                }).sort((a, b) => ((a.sortOrder || 0) < (b.sortOrder || 0) ? -1 : (a.sortOrder || 0) > (b.sortOrder || 0) ? 1 : 0))
              : [],
          FEXFees:
            x.FEXFees && x.FEXFees?.length > 0
              ? x.FEXFees.map(i => {
                  return {
                    id: i.Id,
                    EffectiveDate: getNetDate(i.EffectiveDate),
                    Price: i.Price,
                    value: i.ProductName,
                    UnitOfMeasure: { id: i.UnitOfMeasure?.Id || 0, value: i.UnitOfMeasure?.Value || "" },
                    Period: i.Period,
                    sortOrder: i.SortOrder
                  };
                }).sort((a, b) => ((a.sortOrder || 0) < (b.sortOrder || 0) ? -1 : (a.sortOrder || 0) > (b.sortOrder || 0) ? 1 : 0))
              : [],
          Accounts: accounts
        };
      }),
      ASX: {
        Name: { id: from.ASX?.Name.Id || 1, value: from.ASX?.Name.Value || "ASX" },
        Fees: from.ASX?.Fees
          ? from.ASX?.Fees.map(i => {
              return {
                id: i.Id,
                EffectiveDate: getNetDate(i.EffectiveDate),
                Price: i.Price,
                value: i.ProductName,
                UnitOfMeasure: { id: i.UnitOfMeasure?.Id || 0, value: i.UnitOfMeasure?.Value || "" },
                Period: i.Period,
                sortOrder: i.SortOrder
              };
            }).sort((a, b) => ((a.sortOrder || 0) < (b.sortOrder || 0) ? -1 : (a.sortOrder || 0) > (b.sortOrder || 0) ? 1 : 0))
          : []
      },
      FEX: {
        Name: { id: from.FEX?.Name?.Id || 2, value: from.FEX?.Name?.Value || "FEX" },
        Fees: from.FEX?.Fees
          ? from.FEX?.Fees.map(i => {
              return {
                id: i.Id,
                EffectiveDate: getNetDate(i.EffectiveDate),
                Price: i.Price,
                value: i.ProductName,
                UnitOfMeasure: { id: i.UnitOfMeasure?.Id || 0, value: i.UnitOfMeasure?.Value || "" },
                Period: i.Period,
                sortOrder: i.SortOrder
              };
            }).sort((a, b) => ((a.sortOrder || 0) < (b.sortOrder || 0) ? -1 : (a.sortOrder || 0) > (b.sortOrder || 0) ? 1 : 0))
          : []
      },
      AEMO: {
        Name: { id: from.AEMO?.Name?.Id || 2, value: from.AEMO?.Name?.Value || "AEMO" },
        Fees: from.AEMO?.Fees
          ? from.AEMO?.Fees.map(i => {
              return {
                id: i.Id,
                EffectiveDate: getNetDate(i.EffectiveDate),
                Price: i.Price,
                value: i.ProductName,
                UnitOfMeasure: { id: i.UnitOfMeasure?.Id || 0, value: i.UnitOfMeasure?.Value || "" },
                Period: i.Period,
                sortOrder: i.SortOrder
              };
            }).sort((a, b) => ((a.sortOrder || 0) < (b.sortOrder || 0) ? -1 : (a.sortOrder || 0) > (b.sortOrder || 0) ? 1 : 0))
          : []
      }
    };

    yield put(DefaultFeesSuccess(to));
  } catch (error) {
    const e = error as any;
    yield put(DefaultFeesFailed({ Message: e.message, Status: e.response?.status, StatusText: e.response?.statusText }));
  }
}

// watchers
export function* watchGetAllProfiles() {
  yield takeEvery(PROFILE_GET_ALL_REQUEST, getAllProfilesWorker);
}
export function* watchUpdateProfile() {
  yield takeEvery(PROFILE_UPDATE_REQUEST, updateProfilesWorker);
}
export function* watchUpdateProfileDetails() {
  yield takeEvery(PROFILE_UPDATE_DETAILS_REQUEST, updateProfileDetailsWorker);
}
export function* watchUpdatePeriods() {
  yield takeEvery(PROFILE_UPDATE_PERIODS_REQUEST, updateProfilePeriodsWorker);
}
export function* watchGetInternalDetails() {
  yield takeLatest(INTERNAL_DETAILS_REQUESTED, getInternalDetailsWorker);
}
export function* watchSetInternalDetails() {
  yield takeLatest(INTERNAL_DETAILS_UPDATE_REQUESTED, setInternalDetailsWorker);
}
export function* watchGetDefaultFees() {
  yield takeLatest(DEFAULT_FEES_REQUESTED, getDefaultFeesWorker);
}
export function* watchSetDefaultFees() {
  yield takeLatest(DEFAULT_FEES_UPDATE_REQUESTED, setDefaultFeesWorker);
}

export function* watchGetMarketSettings() {
  const channel: TakeableChannel<ConfigBaseAction<Requestor>> = yield actionChannel(MARKET_SETTINGS_REQUESTED);
  let lastRequestor = -1;

  while (true) {
    const action: ConfigBaseAction<Requestor> = yield take<ConfigBaseAction<Requestor>>(channel);
    const id = action.payload?.RequestorId || 0;

    if (lastRequestor !== id) {
      yield getMarketSettingsWorker(action);
      lastRequestor = id;
    }
  }
}
export function* watchSetMarketSettings() {
  yield takeLatest(MARKET_SETTINGS_UPDATE_REQUESTED, setMarketSettingsUpdateWorker);
}
export function* watchGetUnderlyingQuarters() {
  yield takeEvery(UNDERLYING_QUARTERLY_REQUESTED, getUnderlyingQuarterlyWorker);
}