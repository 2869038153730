import { AuditLogModel, DealModel } from "sagas/api";
import { IApiError } from "sagas/types/config";
import { DealActionResult, DealModelAction } from "../types/deals";

export { rootSaga } from "../controllers/root";

export const SUBMIT_DEAL_REQUEST = "DEALS/SUBMIT_DEAL_REQUEST";
export const SUBMIT_DEAL_SUCCEEDED = "DEALS/SUBMIT_DEAL_SUCCEEDED";
export const SUBMIT_DEAL_FAILED = "DEALS/SUBMIT_DEAL_FAILED";
export const SUBMIT_DEAL_CANCEL = "DEALS/SUBMIT_DEAL_CANCEL";
export const SUBMIT_DEAL_CLEAR = "DEALS/SUBMIT_DEAL_CLEAR";

export const GET_LOG_REQUEST = "DEALS/GET_LOG_REQUEST";
export const GET_LOG_SUCCEEDED = "DEALS/GET_LOG_SUCCEEDED";
export const GET_LOG_FAILED = "DEALS/GET_LOG_FAILED";

export const GET_DEALS_REQUEST = "DEALS/GET_DEALS_REQUEST";
export const GET_DEALS_SUCCEEDED = "DEALS/GET_DEALS_SUCCEEDED";
export const GET_DEALS_FAILED = "DEALS/GET_DEALS_FAILED";

export interface DealsBaseAction<T> {
  type: string;
  payload?: T;
}

// action creators
export const SubmitDealRequest = (deal: DealModelAction): DealsBaseAction<DealModelAction> => ({
  type: SUBMIT_DEAL_REQUEST,
  payload: deal
});

export const SubmitDealSucceeded = (deal: DealActionResult): DealsBaseAction<DealActionResult> => ({
  type: SUBMIT_DEAL_SUCCEEDED,
  payload: deal
});

export const SubmitDealFailed = (message: IApiError): DealsBaseAction<IApiError> => ({
  type: SUBMIT_DEAL_FAILED,
  payload: message
});

export const SubmitDealCancel = (date: Date): DealsBaseAction<Date> => ({
  type: SUBMIT_DEAL_CANCEL,
  payload: date
});

export const ClearDeal = (deal: boolean): DealsBaseAction<boolean> => ({
  type: SUBMIT_DEAL_CLEAR,
  payload: deal
});

export const GetLogRequest = (dealid: number): DealsBaseAction<number> => ({
  type: GET_LOG_REQUEST,
  payload: dealid
});

export const GetLogSucceeded = (deal: AuditLogModel[]): DealsBaseAction<AuditLogModel[]> => ({
  type: GET_LOG_SUCCEEDED,
  payload: deal
});

export const GetLogFalied = (message: IApiError): DealsBaseAction<IApiError> => ({
  type: GET_LOG_FAILED,
  payload: message
});

export const GetDealsRequest = (requestId: number): DealsBaseAction<number> => ({
  type: GET_DEALS_REQUEST,
  payload: requestId
});

export const GetDealsSucceeded = (deal: DealModel[]): DealsBaseAction<DealModel[]> => ({
  type: GET_DEALS_SUCCEEDED,
  payload: deal
});

export const GetDealsFalied = (message: IApiError): DealsBaseAction<IApiError> => ({
  type: GET_DEALS_FAILED,
  payload: message
});

// reducer
export interface DealsSagaState {
  SubmitDeal: {
    request?: DealModelAction;
    reply?: { DealActionResult: DealActionResult; DealAction: DealModelAction };
    error?: IApiError;
    cancel?: Date;
  };
  GetLogs: {
    request?: number;
    reply?: AuditLogModel[];
    error?: IApiError;
    cancel?: Date;
  };
  GetDeals: {
    request?: number;
    reply?: DealModel[];
    error?: IApiError;
  };
}

const initState: DealsSagaState = { SubmitDeal: {}, GetLogs: {}, GetDeals: {} };

export const DealsReducer = (state: DealsSagaState = initState, action: DealsBaseAction<any>) => {
  switch (action.type) {
    case SUBMIT_DEAL_CLEAR:
      return { ...initState };
    case SUBMIT_DEAL_REQUEST:
      return { ...state, SubmitDeal: { request: action.payload } };
    case SUBMIT_DEAL_CANCEL:
      return { ...state, SubmitDeal: { cancel: action.payload } };
    case SUBMIT_DEAL_SUCCEEDED:
      return { ...state, SubmitDeal: { request: state.SubmitDeal.request, reply: action.payload } };
    case SUBMIT_DEAL_FAILED:
      return { ...state, SubmitDeal: { request: state.SubmitDeal.request, error: action.payload } };

    case GET_LOG_REQUEST:
      return { ...state, GetLogs: { request: action.payload } };
    case GET_LOG_FAILED:
      return { ...state, GetLogs: { request: state.GetLogs.request, error: action.payload } };
    case GET_LOG_SUCCEEDED:
      return { ...state, GetLogs: { request: state.GetLogs.request, reply: action.payload } };

    case GET_DEALS_REQUEST:
      return { ...state, GetDeals: { ...state.GetDeals, request: action.payload, error: undefined } };
    case GET_DEALS_FAILED:
      return { ...state, GetDeals: { ...state.GetDeals, error: action.payload } };
    case GET_DEALS_SUCCEEDED:
      return { ...state, GetDeals: { request: state.GetDeals.request, reply: action.payload, error: undefined } };
  }
  return state;
};
