import { BaseQueryApi, createApi } from "@reduxjs/toolkit/query/react";
import { ApiClients } from "api/clients";
import { LegacyDealApi } from "api/legacyApi";

const getError = (exception: unknown, api: BaseQueryApi) =>
  exception instanceof Error ? exception.message : `An error occurred (${api.type}: ${api.endpoint}).`;

export const profileApi = createApi({
  baseQuery: () => ({ data: [] }),
  reducerPath: "legacyProfileApi",
  endpoints: builder => ({
    loadProfiles: builder.query<LegacyDealApi.ProfileGetSummaryResponseModel, void>({
      async queryFn(_, api) {
        try {
          const response = await ApiClients.legacy.profilesApi.getSummary(api.signal);
          return { data: response.Body };
        } catch (exception: unknown) {
          return { error: getError(exception, api) };
        }
      }
    }),
    loadProfileById: builder.query<LegacyDealApi.ProfileGetResponseModel, string>({
      serializeQueryArgs: arg => JSON.stringify(arg || {}).toLowerCase(),
      async queryFn(arg, api) {
        try {
          const response = await ApiClients.legacy.profilesApi.getProfile(arg, api.signal);

          return { data: response.Body };
        } catch (exception: unknown) {
          return { error: getError(exception, api) };
        }
      }
    }),
    loadPeriodHours: builder.query<LegacyDealApi.ContractPeriodModel | undefined, LegacyDealApi.ProfileHoursRequestModel>({
      serializeQueryArgs: arg => JSON.stringify(arg || {}).toLowerCase(),
      async queryFn(arg, api) {
        if (!arg || !arg.VolumeProfileId) return { data: undefined };
        try {
          const response = await ApiClients.legacy.configApi.getHoursInPeriod(arg, api.signal);
          return { data: (response.Body as any)?.Data || response.Body };
        } catch (exception: unknown) {
          return { error: getError(exception, api) };
        }
      }
    }),
    expandProfile: builder.query<
      LegacyDealApi.ProfileExpandedGetResponseModel | undefined,
      { profileId: string; startDate: string; endDate: string; market: string; state: string; frequency?: number }
    >({
      serializeQueryArgs: arg => JSON.stringify(arg || {}).toLowerCase(),
      async queryFn(arg, api) {
        if (
          !arg ||
          !arg.profileId ||
          !arg.startDate ||
          !arg.endDate ||
          !arg.market ||
          !arg.state ||
          (arg.frequency != null && arg.frequency < 1)
        )
          return { data: undefined };
        try {
          const response = await ApiClients.legacy.profilesApi.getProfileExpanded(
            arg.profileId,
            arg.startDate,
            arg.endDate,
            arg.market,
            arg.state,
            arg.frequency,
            api.signal
          );
          return { data: (response.Body as any)?.Data || response.Body };
        } catch (exception: unknown) {
          return { error: getError(exception, api) };
        }
      }
    })
  })
});

export const { useLoadProfilesQuery, useLoadProfileByIdQuery, useLoadPeriodHoursQuery, useExpandProfileQuery } = profileApi;

export default profileApi;
