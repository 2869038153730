import { put, takeEvery, call } from "redux-saga/effects";
import axios, { AxiosResponse } from "axios";
import { LoginService } from "internal/dcc-services/LoginService";
import {
  ProfileDeleteRequestResponseModel,
  ProfileDeleteRequestResponseModelIApiResponse,
  ObjectIApiResponse,
  ProfileCreateRequestModel,
  ProfileCreateResponseModel,
  ProfileCreateResponseModelIApiResponse,
  ProfileDisplayPriorityGetResponseModel,
  ProfileDisplayPriorityGetResponseModelIApiResponse,
  ProfileGetResponseModel,
  ProfileGetResponseModelIApiResponse,
  ProfileNormalisationsGetResponseModel,
  ProfileNormalisationsGetResponseModelIApiResponse,
  ProfileResolutionsGetResponseModel,
  ProfileResolutionsGetResponseModelIApiResponse,
  ProfilesApiFactory,
  ProfileSeasonsGetResponseModel,
  ProfileSeasonsGetResponseModelIApiResponse,
  ProfileTypesGetResponseModel,
  ProfileTypesGetResponseModelIApiResponse,
  ProfileUpdateResponseModel,
  ProfileUpdateResponseModelIApiResponse,
  ProfileUsageGetResponseModel,
  ProfileUsageGetResponseModelIApiResponse,
  ProfileUpdateRequestModel,
  ProfileExpandQueryModel,
  ProfileExpandedGetResponseModel
} from "sagas";
import {
  CreateProfileFailed,
  CreateProfileSuccess,
  CREATE_PROFILE_REQUEST_V2,
  DeleteProfileFailed,
  DeleteProfileRequestFailed,
  DeleteProfileRequestSuccess,
  DeleteProfileSuccess,
  DELETE_PROFILE_REQUEST_REQUEST_V2,
  DELETE_PROFILE_REQUEST_V2,
  GetDisplayPrioritiesFailed,
  GetDisplayPrioritiesSuccess,
  GetProfileExpandedFailed,
  GetProfileExpandedSuccess,
  GetProfileFailed,
  GetProfileNormalisationsFailed,
  GetProfileNormalisationsSuccess,
  GetProfileResolutionsFailed,
  GetProfileResolutionsSuccess,
  GetProfileSeasonsFailed,
  GetProfileSeasonsSuccess,
  GetProfileSuccess,
  GetProfileTypesFailed,
  GetProfileTypesSuccess,
  GetProfileUsagesFailed,
  GetProfileUsagesSuccess,
  GET_DISPLAY_PRIORITIES_REQUEST_V2,
  GET_PROFILE_EXPAND_REQUEST_V2,
  GET_PROFILE_NORMALISATIONS_REQUEST_V2,
  GET_PROFILE_REQUEST_V2,
  GET_PROFILE_RESOLUTIONS_REQUEST_V2,
  GET_PROFILE_SEASONS_REQUEST_V2,
  GET_PROFILE_TYPES_REQUEST_V2,
  GET_PROFILE_USAGES_REQUEST_V2,
  ProfileBaseAction,
  UpdateProfileFailed,
  UpdateProfileSuccess,
  UPDATE_PROFILE_REQUEST_V2
} from "sagas/reducers/profiles";
import { BASE_PATH } from "saga-config";

const profilesClient = ProfilesApiFactory();

function* createProfileWorker(action: ProfileBaseAction<ProfileCreateRequestModel>) {
  try {
    axios.defaults.headers.common["Authorization"] = `Bearer ${LoginService.token()}`;

    const reply: AxiosResponse<ProfileCreateResponseModelIApiResponse> = yield call(profilesClient.apiV2ProfilesPost, action.payload);

    if (reply.data.Status === "Failed" && reply.data.Error && reply.data.Error?.length > 0) {
      for (let i = 0; i < reply.data.Error.length; i++) {
        yield put(
          CreateProfileFailed({
            Message: reply.data.Error[i]?.Message || "",
            Status: Number(reply.data.Error[i]?.Code),
            StatusText: "Failed"
          })
        );
      }
    }
    yield put(CreateProfileSuccess(reply?.data.Body as ProfileCreateResponseModel));
  } catch (error) {
    const e = error as any;

    yield put(CreateProfileFailed({ Message: e.message, Status: e.response?.status, StatusText: e.response?.statusText }));
  }
}

function* getProfileWorker(action: ProfileBaseAction<string | null>) {
  try {
    axios.defaults.headers.common["Authorization"] = `Bearer ${LoginService.token()}`;

    const reply: AxiosResponse<ProfileGetResponseModelIApiResponse> = yield call(
      profilesClient.apiV2ProfilesProfileIdGet,
      action.payload || ""
    );

    yield put(GetProfileSuccess(reply?.data.Body as ProfileGetResponseModel));
  } catch (error) {
    const e = error as any;
    yield put(GetProfileFailed({ Message: e.message, Status: e.response?.status, StatusText: e.response?.statusText }));
  }
}

type profType = { profileId: string | null; model: ProfileUpdateRequestModel };
function* updateProfileWorker(action: ProfileBaseAction<profType>) {
  try {
    axios.defaults.headers.common["Authorization"] = `Bearer ${LoginService.token()}`;

    const reply: AxiosResponse<ProfileUpdateResponseModelIApiResponse> = yield call(
      profilesClient.apiV2ProfilesProfileIdPut,
      action.payload?.profileId as string,
      action.payload?.model
    );
    if (reply.data.Status === "Failed" && reply.data.Error && reply.data.Error?.length > 0) {
      for (let i = 0; i < reply.data.Error.length; i++) {
        yield put(
          UpdateProfileFailed({
            Message: reply.data.Error[i]?.Message || "",
            Status: Number(reply.data.Error[i]?.Code),
            StatusText: "Failed"
          })
        );
      }
    }
    yield put(UpdateProfileSuccess(reply?.data.Body as ProfileUpdateResponseModel));
  } catch (error) {
    const e = error as any;
    yield put(UpdateProfileFailed({ Message: e.message, Status: e.response?.status, StatusText: e.response?.statusText }));
  }
}

function* deleteProfileRequestWorker(action: ProfileBaseAction<string | null>) {
  try {
    axios.defaults.headers.common["Authorization"] = `Bearer ${LoginService.token()}`;

    const reply: AxiosResponse<ProfileDeleteRequestResponseModelIApiResponse> = yield call(
      profilesClient.apiV2ProfilesProfileIdDeletePost,
      action.payload || ""
    );
    yield put(DeleteProfileSuccess(null));
    yield put(DeleteProfileRequestSuccess(reply?.data.Body as ProfileDeleteRequestResponseModel));
  } catch (error) {
    const e = error as any;
    yield put(DeleteProfileRequestFailed({ Message: e.message, Status: e.response?.status, StatusText: e.response?.statusText }));
  }
}

function* deleteProfileWorker(action: ProfileBaseAction<{ profileId: string | null; authKey: string | null }>) {
  try {
    axios.defaults.headers.common["Authorization"] = `Bearer ${LoginService.token()}`;
    axios.defaults.headers.common["EE-DELETE-AUTHORISATION-KEY"] = action.payload?.authKey || "";
    const reply: AxiosResponse<ObjectIApiResponse> = yield call(
      profilesClient.apiV2ProfilesProfileIdDelete,
      action.payload?.profileId || ""
    );

    yield put(DeleteProfileRequestSuccess(null));
    yield put(DeleteProfileSuccess(reply?.data.Status as any));
  } catch (error) {
    const e = error as any;
    yield put(DeleteProfileFailed({ Message: e.message, Status: e.response?.status, StatusText: e.response?.statusText }));
  } finally {
    axios.defaults.headers.common["EE-DELETE-AUTHORISATION-KEY"] = null;
  }
}

function* getProfileSessionsWorker(action: ProfileBaseAction<string | null>) {
  try {
    axios.defaults.headers.common["Authorization"] = `Bearer ${LoginService.token()}`;

    const reply: AxiosResponse<ProfileSeasonsGetResponseModelIApiResponse> = yield call(
      profilesClient.apiV2ProfilesSeasonsGet,
      action.payload || ""
    );

    yield put(GetProfileSeasonsSuccess(reply?.data.Body as ProfileSeasonsGetResponseModel));
  } catch (error) {
    const e = error as any;
    yield put(GetProfileSeasonsFailed({ Message: e.message, Status: e.response?.status, StatusText: e.response?.statusText }));
  }
}

function* getProfileTypesWorker(action: ProfileBaseAction<string | null>) {
  try {
    axios.defaults.headers.common["Authorization"] = `Bearer ${LoginService.token()}`;

    const reply: AxiosResponse<ProfileTypesGetResponseModelIApiResponse> = yield call(
      profilesClient.apiV2ProfilesTypesGet,
      action.payload || ""
    );

    yield put(GetProfileTypesSuccess(reply?.data.Body as ProfileTypesGetResponseModel));
  } catch (error) {
    const e = error as any;
    yield put(GetProfileTypesFailed({ Message: e.message, Status: e.response?.status, StatusText: e.response?.statusText }));
  }
}

function* getProfileResolutionsWorker(action: ProfileBaseAction<string | null>) {
  try {
    axios.defaults.headers.common["Authorization"] = `Bearer ${LoginService.token()}`;

    const reply: AxiosResponse<ProfileResolutionsGetResponseModelIApiResponse> = yield call(
      profilesClient.apiV2ProfilesResolutionsGet,
      action.payload || ""
    );

    yield put(GetProfileResolutionsSuccess(reply?.data.Body as ProfileResolutionsGetResponseModel));
  } catch (error) {
    const e = error as any;
    yield put(
      GetProfileResolutionsFailed({
        Message: e.message,
        Status: e.response?.status,
        StatusText: e.response?.statusText
      })
    );
  }
}

function* getProfileNormalisationsWorker(action: ProfileBaseAction<string | null>) {
  try {
    axios.defaults.headers.common["Authorization"] = `Bearer ${LoginService.token()}`;

    const reply: AxiosResponse<ProfileNormalisationsGetResponseModelIApiResponse> = yield call(
      profilesClient.apiV2ProfilesNormalisationsGet,
      action.payload || ""
    );

    yield put(GetProfileNormalisationsSuccess(reply?.data.Body as ProfileNormalisationsGetResponseModel));
  } catch (error) {
    const e = error as any;
    yield put(
      GetProfileNormalisationsFailed({
        Message: e.message,
        Status: e.response?.status,
        StatusText: e.response?.statusText
      })
    );
  }
}

function* getProfileDisplayPrioritiesWorker(action: ProfileBaseAction<string | null>) {
  try {
    axios.defaults.headers.common["Authorization"] = `Bearer ${LoginService.token()}`;

    const reply: AxiosResponse<ProfileDisplayPriorityGetResponseModelIApiResponse> = yield call(
      profilesClient.apiV2ProfilesPrioritiesGet,
      action.payload || ""
    );

    yield put(GetDisplayPrioritiesSuccess(reply?.data.Body as ProfileDisplayPriorityGetResponseModel));
  } catch (error) {
    const e = error as any;
    yield put(GetDisplayPrioritiesFailed({ Message: e.message, Status: e.response?.status, StatusText: e.response?.statusText }));
  }
}

function* getProfileUsagesWorker(action: ProfileBaseAction<string | null>) {
  try {
    axios.defaults.headers.common["Authorization"] = `Bearer ${LoginService.token()}`;

    const reply: AxiosResponse<ProfileUsageGetResponseModelIApiResponse> = yield call(
      profilesClient.apiV2ProfilesUsagesGet,
      action.payload || ""
    );

    yield put(GetProfileUsagesSuccess(reply?.data.Body as ProfileUsageGetResponseModel));
  } catch (error) {
    const e = error as any;
    yield put(GetProfileUsagesFailed({ Message: e.message, Status: e.response?.status, StatusText: e.response?.statusText }));
  }
}

function* getProfileExpandedWorker(action: ProfileBaseAction<ProfileExpandQueryModel>) {
  try {
    axios.defaults.headers.common["Authorization"] = `Bearer ${LoginService.token()}`;

    const p = action.payload;
    const url = `/api/v1/v2/profiles/${action.payload?.profileId}/expand?StartDate=${p?.StartDate}&EndDate=${p?.EndDate}&Market=${p?.Market}&State=${p?.State}`;

    const reply: { data: { Body: ProfileExpandedGetResponseModel } } = yield axios.get(BASE_PATH + url);

    yield put(GetProfileExpandedSuccess(reply?.data?.Body as ProfileExpandedGetResponseModel));
  } catch (error) {
    const e = error as any;
    yield put(GetProfileExpandedFailed({ Message: e.message, Status: e.response?.status, StatusText: e.response?.statusText }));
  }
}

// watchers
export function* watchCreateProfile() {
  yield takeEvery(CREATE_PROFILE_REQUEST_V2, createProfileWorker);
}
export function* watchGetProfile() {
  yield takeEvery(GET_PROFILE_REQUEST_V2, getProfileWorker);
}
export function* watchUpdateProfileV2() {
  yield takeEvery(UPDATE_PROFILE_REQUEST_V2, updateProfileWorker);
}
export function* watchDeleteProfileRequest() {
  yield takeEvery(DELETE_PROFILE_REQUEST_REQUEST_V2, deleteProfileRequestWorker);
}
export function* watchDeleteProfile() {
  yield takeEvery(DELETE_PROFILE_REQUEST_V2, deleteProfileWorker);
}
export function* watchGetProfileSeasons() {
  yield takeEvery(GET_PROFILE_SEASONS_REQUEST_V2, getProfileSessionsWorker);
}
export function* watchGetProfileTypes() {
  yield takeEvery(GET_PROFILE_TYPES_REQUEST_V2, getProfileTypesWorker);
}
export function* watchGetProfileResolutions() {
  yield takeEvery(GET_PROFILE_RESOLUTIONS_REQUEST_V2, getProfileResolutionsWorker);
}
export function* watchGetProfileNormalisations() {
  yield takeEvery(GET_PROFILE_NORMALISATIONS_REQUEST_V2, getProfileNormalisationsWorker);
}
export function* watchGetProfileDisplayPriorities() {
  yield takeEvery(GET_DISPLAY_PRIORITIES_REQUEST_V2, getProfileDisplayPrioritiesWorker);
}
export function* watchGetProfileUsages() {
  yield takeEvery(GET_PROFILE_USAGES_REQUEST_V2, getProfileUsagesWorker);
}
export function* watchGetProfileExpanded() {
  yield takeEvery(GET_PROFILE_EXPAND_REQUEST_V2, getProfileExpandedWorker);
}
