import { SerializedError } from "@reduxjs/toolkit";
import { BaseQueryFn, createApi } from "@reduxjs/toolkit/query/react";
import { ApiClients } from "api/clients";
import { MarketDealIntermediariesApi } from "api/intermediariesApiClient";
import { LegacyDealApi } from "api/legacyApi";
import dateTimeHelper from "helpers/dateTimeHelper";

// const getError = (exception: unknown, api: BaseQueryApi) =>
//   exception instanceof Error ? exception.message : `An error occurred (${api.type}: ${api.endpoint}).`;

export const feesApi = createApi({
  reducerPath: "feesApi",
  baseQuery: (() => ({ data: undefined, error: undefined })) as BaseQueryFn<void, LegacyDealApi.FeesModel, SerializedError | undefined>,
  endpoints: builder => ({
    loadDefaultFees: builder.query<LegacyDealApi.FeesModel, void>({
      queryFn: async (_, api) => {
        try {
          const fees = await ApiClients.legacy.configApi.getDefaultFees(api.signal);

          if ((fees?.Error?.length || 0) > 0 && fees.Status !== LegacyDealApi.ApiResponseStatus.Success) {
            return {
              error: {
                code: fees.Error![0]?.Code || "",
                message: fees.Error![0]?.Message || ""
              }
            };
          }

          return { data: fees.Body };
        } catch (error) {
          return {
            error: {
              code: (error as Error)?.name,
              message: (error as Error)?.message
            }
          };
        }
      }
    }),
    loadMarketBrokerFees: builder.query<
      MarketDealIntermediariesApi.MarketIntermediaryDefaultFee[],
      { id: number; marketId: string; date?: Date }
    >({
      queryFn: async ({ id, marketId, date }, api) => {
        try {
          const response = await ApiClients.deals.intermediariesClient.listMarketBrokerFees(
            id,
            marketId,
            date ? dateTimeHelper.getDateOnly(date) : undefined
          );

          return { data: response?.DefaultFees || [], error: undefined };
        } catch (error) {
          return {
            data: undefined,
            error:
              error instanceof Error
                ? { message: error.message, name: error.name }
                : {
                    message: "Unexpected error occurred."
                  }
          };
        }
      }
    }),
    loadMarketClearerFees: builder.query<
      MarketDealIntermediariesApi.MarketIntermediaryDefaultFee[],
      { id: number; marketId: string; date?: Date }
    >({
      queryFn: async ({ id, marketId, date }, api) => {
        try {
          const response = await ApiClients.deals.intermediariesClient.listMarketClearerFees(
            id,
            marketId,
            date ? dateTimeHelper.getDateOnly(date) : undefined
          );

          return { data: response?.DefaultFees || [], error: undefined };
        } catch (error) {
          return {
            data: undefined,
            error:
              error instanceof Error
                ? { message: error.message, name: error.name }
                : {
                    message: "Unexpected error occurred."
                  }
          };
        }
      },
      extraOptions: {}
    })
  })
});

export const { useLoadDefaultFeesQuery, useLoadMarketBrokerFeesQuery, useLoadMarketClearerFeesQuery } = feesApi;
